.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.backdrop {
  background: rgb(0 3 46 / 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal {
  z-index: 1;
  width: 90rem;
  background-image: url('../assets/modal-bg.svg'), var(--gradient-bg-modal);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 1.5rem var(--shadow-modal);
  border: 0.4rem solid var(--white);
  padding: 3rem 6rem 0;
  box-sizing: border-box;
  font-size: 3.5rem;
  font-family: 'Play', sans-serif;
  border-radius: 0 7rem 0 7rem;
  text-align: center;
  position: relative;
}

.field {
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--separator);
  position: relative;
  margin: 3rem 4rem;
}

.field::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--separator);
  bottom: -0.5rem;
  position: absolute;
  transform: rotate(45deg);
  right: -0.5rem;
}

.field::after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--separator);
  bottom: -0.5rem;
  position: absolute;
  transform: rotate(45deg);
  left: -0.5rem;
}

.field > label {
  font-family: 'Play', sans-serif;
  font-weight: bold;
}

.field > input {
  font-family: 'Jura', sans-serif;
  background-color: transparent;
  border: none;
  font-size: 3rem;
  height: 3rem;
  width: 100%;
  outline: none;
  color: var(--text-alternative-dark);
  font-weight: bold;
  margin-top: 1rem;
}

.message {
  font-family: 'Josefin Sans', sans-serif;
  color: var(--text-alternative);
  font-style: italic;
  font-size: 3rem;
  text-align: center;
  padding: 3rem;
}

.submit {
  background: transparent;
  background-image: url('../assets/button-bg-modal.png');
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
  background-position: center;
  width: 45rem;
  aspect-ratio: 4 / 1;
  border: none;
  font-size: 4rem;
  font-weight: bold;
  color: var(--white);
  transform: translateY(5rem);
  font-family: 'Jura', sans-serif;
  line-height: 1.1;
  margin-top: -4rem;
}

.numeric-input::-webkit-outer-spin-button,
.numeric-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numeric-input[type='number'] {
  -moz-appearance: textfield;
}
