.container {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--text-alternative-dark);
  position: relative;
  margin: 0 4rem;
}

.container::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--text-alternative-dark);
  bottom: -0.5rem;
  position: absolute;
  transform: rotate(45deg);
  right: -0.5rem;
}

.container::after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--text-alternative-dark);
  bottom: -0.5rem;
  position: absolute;
  transform: rotate(45deg);
  left: -0.5rem;
}

.input {
  font-family: 'Jura', sans-serif;
  background-color: transparent;
  border: none;
  font-size: 2.5rem;
  height: 3rem;
  width: 100%;
  outline: none;
}
