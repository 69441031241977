.card {
  font-family: 'Play', sans-serif;
  width: 60rem;
  background-color: var(--white);
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 0 1.2rem #00000031;
}

.header {
  padding: 1rem;
}

.image {
  width: 100%;
}

.button {
  background: transparent;
  background-image: url('../../assets/card-bg-button.svg');
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
  background-position: center;
  width: 20rem;
  aspect-ratio: 4 / 1;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  transform: translateY(2.5rem);
  font-family: 'Jura', sans-serif;
  line-height: 1.1;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
