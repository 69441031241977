.content {
  display: grid;
  grid-template-rows: 15rem calc(100vh - 15rem);
  height: 100%;
}

.nav {
  display: grid;
  grid-template-columns: 40% auto;
  align-items: center;
  padding-top: 6rem;
  box-sizing: border-box;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 3rem 6rem;
  overflow-y: scroll;
  gap: 4rem;
  justify-content: center;
  background-image: linear-gradient(
    270deg,
    rgba(128, 123, 232, 0.2) 0%,
    rgba(149, 174, 241, 0.1) 55%,
    rgba(183, 255, 255, 0) 100%
  );
  align-items: flex-start;
}

.searchbg {
  position: absolute;
  top: 0;
  height: calc(100vh - 15rem);
  z-index: 0;
}

@media (max-width: 1024px) {
  .content {
    grid-template-rows: 19rem calc(100vh - 19rem);
  }

  .nav {
    grid-template-columns: 100%;
    row-gap: 2rem;
  }
}

.listend {
  width: 100%;
}

@media (max-width: 768px) {
  .content {
    display: block;
  }
}
