.card {
  font-family: 'Play', sans-serif;
  width: 32rem;
  background-color: var(--gauntlet-card-bg);
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 0 1.2rem #00000031;
  height: auto;
  color: var(--white);
  position: relative;
}
.card:hover .description {
  opacity: 1;
}

.description {
  opacity: 0;
  color: var(--white);
  position: absolute;
  padding: 3rem;
  box-sizing: border-box;
  top: 6rem;
  font-size: 1.8rem;
  background: rgb(0 0 0 / 70%);
  transition: opacity 0.3s ease-in-out;
  overflow-y: scroll;
  z-index: 2;
  height: 80%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.description::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.description {
  scrollbar-width: none; /* Firefox */
}

.header {
  font-size: 2rem;
  padding: 1rem 3rem;
  height: 5rem;
}

.image {
  width: 70%;
  aspect-ratio: 1 / 1;
}

.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 3rem;
  position: relative;
}

.stats .bg {
  position: absolute;
  width: 100%;
  left: 0;
  height: 11rem;
  object-fit: contain;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat img {
  height: 3rem;
  width: 3rem;
}
.stat span {
  font-size: 1.5rem;
}

.rarity img {
  height: 4rem;
  width: 4rem;
}

.rarity span {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.id {
  display: block;
  font-size: 1.8rem;
  padding-top: 1rem;
}

.button {
  background: transparent;
  background-image: url('../../assets/card-bg-button.svg');
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
  background-position: center;
  width: 20rem;
  aspect-ratio: 4 / 1;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  transform: translateY(2.5rem);
  font-family: 'Jura', sans-serif;
  line-height: 1.1;
  margin-top: -3rem;
}

@media (max-width: 768px) {
  .card {
    width: 27rem;
  }
}
