.nav {
  list-style: none;
  display: flex;
  justify-content: space-around;
  background-image: linear-gradient(
    270deg,
    rgba(128, 123, 232, 0.2) 0%,
    rgba(149, 174, 241, 0.1) 77%,
    rgba(183, 255, 255, 0) 100%
  );
  height: 100%;
  align-items: center;
  padding-bottom: 1rem;
  box-sizing: border-box;
  gap: 2rem;
  margin: 0;
  padding-right: 5rem;
}

.item {
  width: 100%;
}

.button {
  width: 100%;
  height: 5rem;
  font-family: 'Jura', sans-serif;
  font-size: 2.5rem;
  background: transparent;
  border: none;
  outline: none;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
}

.button::before {
  content: '';
  position: absolute;
  width: 90%;
  height: 3rem;
  background: var(--button-color);
  opacity: 0;
  top: 2rem;
  transform: skewX(-45deg) translateY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  left: 5%;
  z-index: -1;
  box-shadow: 0.6rem 0.4rem 0 var(--text-medium);
}

.button:hover,
.tabactive {
  transform: translateY(-1rem);
}

.button:hover.button::before,
.tabactive.button::before {
  transform: skewX(-45deg) translateY(1rem) translatex(1rem);
  opacity: 0.3;
}
