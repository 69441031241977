.container {
  background: var(--gradient-bg);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50rem auto;
  column-gap: 1rem;
}

@media (max-width: 768px) {
  .container {
    display: block;
  }
}
