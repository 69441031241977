.container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  box-sizing: border-box;
}

.logo {
  height: 15rem;
}

.menu {
  background: transparent;
  border: none;
  outline: none;
  width: 8rem;
}

@media (min-width: 768px) {
  .container {
    display: none;
  }
}
