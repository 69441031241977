.close {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  font-size: 5rem;
  text-shadow: -0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.274);
  position: absolute;
  right: 4rem;
  z-index: 1;
}

.content {
  padding: 2rem 0;
  font-size: 4rem;
  font-weight: bold;
}

.content img {
  width: 100%;
  padding: 5rem 2rem;
  box-sizing: border-box;
}
