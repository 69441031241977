:root {
  --bg-light: #ecf9ff;
  --bg-dark: #f3efff;
  --text-dark: #041046;
  --text-medium: #3c3153;
  --text-light: #807be8;
  --text-alternative: #9579d1;
  --text-alternative-dark: #7a62ab;
  --text-medium: #979797;
  --bg-secondary-light: #00e196;
  --bg-secondary-dark: #007a7a;
  --bg-tertiary-light: #e1fefe;
  --white: #ffffff;
  --separator: #d2b5ee;
  --blue: #4ab9ff;
  --light-blue: #8cd0f5;
  --gradient-bg: linear-gradient(0deg, #f3efff 0%, #ecf9ff 100%);
  --gradient-bg-modal: linear-gradient(180deg, rgba(224, 254, 254, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%);
  --shadow-modal: #95ffff;
  --button-color: #b7ffff;
  --gauntlet-card-bg: #150e28;

  /* rarities */
  --plane-rarity: #ea9962;
  --plane-gradient: radial-gradient(circle, rgba(234, 153, 98, 0.4) 0%, rgba(255, 255, 255, 0) 60%);
  --tribe-rarity: #0098ff;
  --tribe-gradient: radial-gradient(circle, rgba(0, 152, 255, 0.4) 0%, rgba(255, 255, 255, 0) 60%);
  --creature-rarity: #ffde00;
  --creature-gradient: radial-gradient(circle, rgba(255, 222, 0, 0.4) 0%, rgba(255, 255, 255, 0) 60%);
  --deity-rarity: #9900ff;
  --deity-gradient: radial-gradient(circle, rgba(153, 0, 255, 0.4) 0%, rgba(255, 255, 255, 0) 60%);
}
html {
  font-size: 7px;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  font-size: 2.5rem;
}

@media (max-width: 1500px) {
  html {
    font-size: 6px;
  }
}

button:disabled {
  filter: grayscale(1);
}

.link-disabled {
  filter: grayscale(1);
  opacity: 0.6;
}

@media (max-width: 768px) {
  html,
  body,
  #root {
    width: auto;
    height: auto;
  }
}
