.close {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  font-size: 5rem;
  text-shadow: -0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.274);
  position: absolute;
  right: 4rem;
  z-index: 1;
}

.steps {
  display: flex;
  padding: 9rem 3rem 8rem;
  justify-content: space-between;
  height: 15rem;
  position: relative;
}

.steps::before {
  content: '';
  width: 60rem;
  height: 1.5rem;
  background: var(--blue);
  position: absolute;
  top: 14rem;
  left: 8rem;
}

.steps::after {
  content: '';
  width: 30rem;
  height: 1.5rem;
  background: var(--light-blue);
  position: absolute;
  top: 14rem;
  left: 38rem;
}

.step {
  background: url('../../assets/step.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  z-index: 1;
  flex-wrap: wrap;
  gap: 7rem;
  padding-top: 3.2rem;
}

.step span {
  color: var(--text-dark);
  font-size: 3rem;
}

.step img {
  margin-top: 1rem;
}

.step.active {
  background: url('../../assets/current-step.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.separator {
  border-bottom: 0.4rem solid var(--blue);
  position: relative;
  margin: 1rem 4rem;
}

.separator.short {
  position: relative;
  margin: 1rem 10rem;
}

.separator::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--blue);
  bottom: -0.7rem;
  position: absolute;
  transform: rotate(45deg);
  right: -0.5rem;
}

.separator::after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--blue);
  bottom: -0.7rem;
  position: absolute;
  transform: rotate(45deg);
  left: -0.5rem;
}

.content {
  padding: 2rem 0;
  font-size: 3rem;
}

.content .highlight {
  color: var(--text-alternative-dark);
}

.submit {
  width: 55rem;
  font-size: 2.8rem;
  margin: 2rem;
  transform: translateY(0);
}

.note {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.5rem;
  margin: 2rem;
  font-style: italic;
  text-shadow: none;
}

.detail {
  font-family: 'Josefin Sans', sans-serif;
  text-align: left;
  position: relative;
  padding-left: 5rem;
  margin: 4rem 0;
}

.detail::after {
  content: '';
  background: url('../../assets/bullet-icon.svg');
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
