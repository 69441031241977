.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('../../assets/sidebar-bg.png');
  background-size: contain;
  background-position-x: 10rem;
  background-repeat: no-repeat;
  align-items: center;
}

.logo {
  height: 15rem;
  padding: 3rem;
}

.list {
  list-style: none;
  padding-top: 4rem;
  padding-left: 7rem;
  position: relative;
}
.item {
  position: relative;
}
.item a {
  display: flex;
  gap: 2rem;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
  align-items: center;
  font-size: 2.8rem;
  margin-bottom: 6rem;
  color: var(--text-dark);
}

.item a img {
  width: 5rem;
  display: block;
}

.selector {
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
}

.login-button {
  font-family: 'Play', sans-serif;
  font-size: 3rem;
  color: var(--white);
  background: url('../../assets/sign-in-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 9rem;
  aspect-ratio: 6.8 / 1;
  align-items: center;
  justify-content: right;
  padding-right: 7rem;
  padding-bottom: 1rem;
  gap: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 5rem;
  transform: translateX(-5rem);
}
.login-user {
  font-family: 'Play', sans-serif;
  font-size: 3rem;
  color: var(--white);
  background: url('../../assets/signed-in-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 9rem;
  aspect-ratio: 6.8 / 1;
  align-items: center;
  justify-content: right;
  padding-right: 7rem;
  padding-bottom: 1rem;
  gap: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 5rem;
  transform: translateX(-5rem);
  text-shadow: 0px 0px 0.5rem white;
}

.login-button img {
  height: 80%;
}

.login-user img {
  height: 80%;
}

.menu-active {
  transform: translateX(0rem) !important;
}

@media (max-width: 768px) {
  .container {
    position: fixed;
    transform: translateX(-70rem);
    transition: transform 0.3s ease-in-out;
    height: 100vh;
    background: var(--gradient-bg);
    top: 0;
    z-index: 3;
    width: 50rem;
  }

  .logo {
    visibility: hidden;
  }
}
